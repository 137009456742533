$base-line-height: 24px;
$color: rgb(51, 51, 51);
$off-color: rgb(190, 190, 190);
$spin-duration: 1s;
$pulse-duration: 750ms;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  50% {
    background: $color;
  }
}

.loading {
  border-radius: 50%;
  width: $base-line-height;
  height: $base-line-height;
  border: .25rem solid $off-color;
  border-top-color: $color;
  animation: spin $spin-duration infinite linear;
  display: table;
  margin: 0 auto;
}
