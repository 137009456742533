// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Bootstrap 3
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// SB-Admin 2
@import "node_modules/startbootstrap-sb-admin-2/dist/css/sb-admin-2";

// MetisMenu
@import "node_modules/metismenu/dist/metisMenu";

// Toastr
@import "node_modules/toastr/toastr";

// DataTables for Bootstrap
@import "node_modules/datatables.net-bs/css/dataTables.bootstrap";
@import "node_modules/datatables.net-buttons-bs/css/buttons.bootstrap";
@import "node_modules/datatables.net-responsive-bs/css/responsive.bootstrap";

// Select2
@import "node_modules/select2/dist/css/select2";

// FullCalendar
@import "node_modules/fullcalendar/dist/fullcalendar";
@import "node_modules/fullcalendar-scheduler/dist/scheduler";

// Bootstrap-Duration-Picker
@import "node_modules/bootstrap-duration-picker/src/bootstrap-duration-picker";

// Custom Styling:

// Spinner
@import "./custom/spinner";

// Select 2 Error Class
@import "./custom/select2ErrorClass";

// ToastrAlignment
@import "./custom/toastrAlignment";

// DataTable-Styling: Centered content
@import "./custom/datatableBootstrapCenter";

// DataTable-Styling: Icon-Paths
@import "./custom/datatableIcons";

// Custom H1-Styling
@import "./custom/h1";

// Custom FullCalendar-Styling
@import "./custom/fullCalendar";

// LoadingInfo
@import "./custom/loadingInfo";